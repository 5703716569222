import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "../styles/Unit.css";
import * as BsIcons from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getActiveBatch } from "../api_folder/api";

function UnitTKIT() {
  const navigate = useNavigate();

  const [reg, setReg] = useState([]);

  const submitPG = (e) => {
    let unitPG = { id_batch: 82, name_unit: "Playgroup TKIT Asy-Syukriyyah" };
    e.preventDefault();
    navigate("/register-reguler", { state: unitPG });
  };
  const submitA = (e) => {
    let unitA = { id_batch: 83, name_unit: "TKIT A Asy-Syukriyyah" };
    e.preventDefault();
    navigate("/register-reguler", { state: unitA });
  };
  const submitB = (e) => {
    let unitB = { id_batch: 84, name_unit: "TKIT B Asy-Syukriyyah" };
    e.preventDefault();
    navigate("/register-reguler", { state: unitB });
  };
  useEffect(() => {
    getActiveBatch().then((res) => {
      setReg(res.data);
    });
  }, []);

  let activeB = [];
  if (reg.status !== "fail") {
    reg.forEach((ewq) => {
      activeB.push(ewq.name_unit);
    });
  }

  return (
    <section>
      <Navbar expand="sm" variant="custom" bsPrefix={""} sticky="top">
        <Navbar.Brand>
          <img
            src="/images/logo.svg"
            width="44"
            height="45"
            className="d-inline-block align-top ms-3"
            alt="logo"
          />
        </Navbar.Brand>
        <Nav>
          <Nav.Link>PPDB Asy-Syukriyyah Islamic School</Nav.Link>
        </Nav>
      </Navbar>
      <Container className="p-3 p-md-5">
        <Link to="/" className="back">
          <BsIcons.BsArrowLeftShort /> Kembali ke halaman utama
        </Link>
        <span className="circlexunit" />
        <span className="circleyunit" />

        <Row className="mt-5 px-1">
          <Col md={12} lg={8} className="d-flex flex-column gap-3">
            <div>
              <div style={{ color: "#00923F" }}>
                <h1 style={{ color: "#364152" }}>TKIT Asy-Syukriyyah</h1>
              </div>
              <p className="text-body-regsuccess">
                Pendaftaran peserta didik TKIT Asy-Syukriyyah
              </p>
              <hr />
              <div style={{ color: "#364152" }}>
                <h4 style={{ marginTop: "64px" }}>
                  Persyaratan Pendaftaran TKIT Asy-Syukriyyah
                </h4>
                <p className="text-body-unitsuccess">
                  Apa saja yang akan diperlukan untuk mendaftarkan peserta didik
                  baru.
                </p>
              </div>
            </div>
            <div className="infoBox listColored">
              <h6 style={{ color: "#364152" }}>📝 Persyaratan umum</h6>
              <ul>
                <li>
                  Melakukan pendaftaran secara online di alamat{" "}
                  <Link
                    style={{ color: "#00923F" }}
                    to="https://ppdb.asy-syukriyyah.sch.id/"
                  >
                    ppdb.asy-syukriyyah.sch.id
                  </Link>
                </li>
                <li>Mengikuti seleksi observasi dan wawancara orang tua</li>
                <li>Melengkapi berkas dokumen</li>
                <li>
                  Melunasi administrasi keuangan sampai waktu yang ditentukan
                </li>
              </ul>
            </div>
            <div className="infoBox p-3">
              <h6 style={{ color: "#364152" }}>👤 Persyaratan Usia</h6>
              <Row className="justify-content-center gap-2 gap-lg-4 gap-xxl-0 align-items-center unitAge">
                <Col xs={10} sm={6} xl={3} xxl={3} className="btnlookalike">
                  <div className="btnlookalike">
                    <h4>Playgroup</h4>
                    <p style={{ color: "#697586" }}>3 tahun</p>
                  </div>
                </Col>
                <Col xs={10} sm={6} xl={3} xxl={3} className="btnlookalike">
                  <div className="btnlookalike">
                    <h4>TK A</h4>
                    <p style={{ color: "#697586" }}>4 tahun</p>
                  </div>
                </Col>
                <Col xs={10} sm={6} xl={3} xxl={3} className="btnlookalike">
                  <div className="btnlookalike">
                    <h4>TK B</h4>
                    <p style={{ color: "#697586" }}>5 tahun</p>
                  </div>
                </Col>
                <p
                  style={{
                    color: "#697586",
                    textAlign: "center",
                    marginTop: "1rem",
                  }}
                >
                  per Juni 2025
                </p>
              </Row>
            </div>
            <div className="infoBox listColored">
              <h6 style={{ color: "#364152" }}>📑 Persyaratan Dokumen</h6>
              <ul>
                <li>
                  Melakukan pendaftaran secara online di alamat{" "}
                  <Link
                    style={{ color: "#00923F" }}
                    to="https://ppdb.asy-syukriyyah.sch.id/"
                  >
                    ppdb.asy-syukriyyah.sch.id
                  </Link>
                </li>
                <li>Mengikuti seleksi observasi dan wawancara orang tua</li>
                <li>Melengkapi berkas dokumen</li>
                <li>
                  Melunasi administrasi keuangan sampai waktu yang ditentukan
                </li>
              </ul>
            </div>
            <div className="">
              <Row className="gap-4 p-3">
                <Col xs={12} className="infoBox">
                  <h6 style={{ color: "#364152" }}>📝 Ketentuan umum</h6>
                  <ul>
                    <li>
                      Masing-masing pendaftar akan mendapatkan nomor
                      pendaftaran.
                    </li>
                    <li>
                      Pembayaran biaya pendaftaran dilakukan melalui portal IDN
                      (infradigital).
                    </li>
                    <li>
                      Biaya pendaftaran sebagaimana tertera pada bukti
                      pendaftaran ditambah biaya admin.
                    </li>
                    <li>
                      Pembayaran biaya pendaftaran paling lambat 1 hari
                      terhitung dari hari pendaftaran.
                    </li>
                  </ul>
                </Col>
                <Col xs={12} className="infoBox">
                  <h6 style={{ color: "#364152" }}>
                    📄 Langkah-langkah pembayaran formulir registrasi reguler
                  </h6>
                  <ol>
                    <li>
                      Cek tagihan biaya formulir anda di{" "}
                      <Link
                        to={"https://www.infradigital.io/bills"}
                        style={{ color: "#00923F" }}
                      >
                        https://www.infradigital.io/bills
                      </Link>
                    </li>
                    <li>
                      Pilih unit pendidikan di kolom <b>“-Pilih Sekolah-”</b>
                    </li>
                    <li>
                      Masukkan nomor pendaftaran di kolom{" "}
                      <b>“Masukkan Nomor Induk Siswa (NIS)”</b>
                    </li>
                    <li>
                      Klik tombol <b>“Lihat Tagihan”</b>
                    </li>
                    <li>
                      Pastikan nominal tagihan dan nomor pendaftaran sudah
                      sesuai (ada tambahan biaya admin tergantung pilihan
                      pembayaran yg dipilih)
                    </li>
                    <li>
                      Pilih <b>“Cara bayar yang Anda kehendaki”</b>
                    </li>
                    <li>
                      Klik <b>"Lihat Cara Bayar"</b>
                    </li>
                    <li>
                      Ikuti petunjuk pembayaran sesuai yang tertera di{" "}
                      <Link
                        to={"https://www.infradigital.io/bills"}
                        style={{ color: "#00923F" }}
                      >
                        infradigital.io
                      </Link>
                    </li>
                    <li>
                      Konfirmasikan pembayaran biaya pendaftaran anda ke Call
                      Center unit pendidikan terkait untuk aktivasi akun.
                    </li>
                  </ol>
                </Col>
                <Col xs={12} className="infoBox">
                  <h6 style={{ color: "#364152" }}>📅 Kode unit pendidikan</h6>
                  <ul>
                    <li>
                      <span className="primColor">10620</span> - TK Islam
                      Terpadu Asy-Syukriyyah
                    </li>
                    <li>
                      <span className="primColor">10610</span> - MI Plus
                      Asy-Syukriyyah
                    </li>
                    <li>
                      <span className="primColor">10619</span> - SD Islam
                      Terpadu Asy-Syukriyyah
                    </li>
                    <li>
                      <span className="primColor">10697</span> - SDIT Quran
                      Asy-Syukriyyah Faturrahman
                    </li>
                    <li>
                      <span className="primColor">10622</span> - SMP Islam
                      Terpadu Asy-Syukriyyah
                    </li>
                    <li>
                      <span className="primColor">10623</span> - MTs Plus
                      Asy-Syukriyyah
                    </li>
                    <li>
                      <span className="primColor">10621</span> - SMA Islam
                      Terpadu Asy-Syukriyyah
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={"auto"} lg={2} xxl={3} className="mt-3">
            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-1">
              <Col>
                <Card className="cardUnit  mx-auto mx-lg-0 ">
                  <Card.Img
                    variant="top"
                    src="/images/imgtkit.png"
                    alt="TKIT"
                    style={{
                      height: "220px",
                      objectFit: "contain",
                    }}
                    className="img-fluid"
                  />
                  <Card.Body>
                    <Card.Title>TKIT Asy-Syukriyyah</Card.Title>
                    <Card.Text>
                      <span>Pendaftaran dibuka pada</span>{" "}
                      <span style={{ color: "#00923F" }}>18 Oktober 2024</span>
                    </Card.Text>
                    {activeB.includes("Play Group TKIT Asy-Syukriyyah") ? (
                      <Form onSubmit={submitPG}>
                        <Button
                          variant="unit"
                          className="rounded-pill mt-3"
                          type="submit"
                        >
                          Playgroup
                        </Button>
                      </Form>
                    ) : (
                      <Button
                        variant="unitClosed"
                        className="rounded-pill mt-3"
                        type="submit"
                      >
                        Playgroup
                      </Button>
                    )}
                    {activeB.includes("Kelas A TKIT Asy-Syukriyyah") ? (
                      <Form onSubmit={submitA}>
                        <Button
                          variant="unit"
                          className="rounded-pill mt-3"
                          type="submit"
                        >
                          TK A
                        </Button>
                      </Form>
                    ) : (
                      <Button
                        variant="unitClosed"
                        className="rounded-pill mt-3"
                        type="submit"
                      >
                        TK A
                      </Button>
                    )}

                    {activeB.includes("Kelas B TKIT Asy-Syukriyyah") ? (
                      <Form onSubmit={submitB}>
                        <Button
                          variant="unit"
                          className="rounded-pill mt-3"
                          type="submit"
                        >
                          TK B
                        </Button>
                      </Form>
                    ) : (
                      <Button
                        variant="unitClosed"
                        className="rounded-pill mt-3"
                        type="submit"
                      >
                        TK B
                      </Button>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card
                  style={{ marginTop: "1rem" }}
                  className="cardUnit  mx-auto mx-0-lg"
                >
                  <Card.Body>
                    <Card.Title
                      style={{ color: "#00923F", textAlign: "center" }}
                    >
                      Kontak TKIT Asy-Syukriyyah
                    </Card.Title>
                    <Card.Text
                      className="text-center"
                      style={{ color: "#697586" }}
                    >
                      Email: tkit@asy-syukriyyah.sch.id
                    </Card.Text>
                    <Button
                      target="_blank"
                      variant="success"
                      className="rounded-pill mt-3"
                      href="https://wa.me/+6282139045024"
                    >
                      WhatsApp
                    </Button>
                  </Card.Body>
                </Card>
                <Card
                  style={{ marginTop: "1rem" }}
                  className="cardUnit  mx-auto mx-0-lg"
                >
                  <Card.Body>
                    <Card.Title
                      style={{ color: "#00923F", textAlign: "center" }}
                    >
                      Brosur TKIT Asy-Syukriyyah
                    </Card.Title>
                    <Card.Text
                      className="text-center"
                      style={{ color: "#697586" }}
                    >
                      Untuk informasi selanjutnya silahkan download brosur
                      sekolah di bawah ini.
                    </Card.Text>
                    <Button
                      variant="success"
                      className="rounded-pill mt-3"
                      target="_blank"
                      href="https://drive.google.com/file/d/1_Sl8dmqAb_kF8qk8l-wzwWlECQi7g8pB/view?usp=share_link"
                    >
                      Download Brosur
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default UnitTKIT;
