import Axios from "axios";

const url = "https://ppdbnew.asy-syukriyyah.sch.id/api";

export const sendDataForm = (
  usrnm,
  name,
  gender,
  birthplace,
  phoneNumber,
  email,
  school_before,
  others_school,
) => {
  return Axios({
    method: "post",
    url: url + "/ppdb/sendDataForm",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      username: usrnm,
      name_student: name,
      gender: gender,
      birthplace: birthplace,
      phone_number: phoneNumber,
      email: email,
      school_before,
      others_school,
    },
  });
};
export const sendDataPersonal = (
  usrnm, namaLengkap, nickname, nik, nokk, anake, siblingNum, address, rtrw, village, district, city, province, postal, phone, email, nationality, nisn, siblingSch, siblingYesNo, disability, living, transportation, userfile
) => {
  return Axios({
    method: "post",
    url: url + "/ppdb/sendDataPersonal",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      username: usrnm,
      full_name: namaLengkap,
      nick_name: nickname,
      nik: nik,
      nokk: nokk,
      child_no: anake,
      sibling: siblingNum,
      address: address,
      rt_rw: rtrw,
      name_village: village,
      name_district: district,
      name_city: city,
      name_province: province,
      postal_code: postal,
      phone_number: phone,
      email: email,
      nationality: nationality,
      nisn: nisn,
      sibling_school: siblingSch,
      sibling_school_yesno: siblingYesNo,
      disability: disability,
      living_status: living,
      transportation: transportation,
      userfile: userfile,
    },
  });
};

export const sendDataKK = (
  usrnm,
  usrfile,
) => {
  return Axios({
    method: "post",
    url: url + "/ppdb/sendDataKK",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      username: usrnm,
      userfile: usrfile,
    },
  });
};
export const sendDataPeriodik = (
  usrnm,
  tinggi,
  berat,
  pala,
  darah,
  riwayat,
  penyakit,
  jarak,
  waktu,
) => {
  return Axios({
    method: "post",
    url: url + "/ppdb/sendDataPeriod",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      username: usrnm,
      height: tinggi,
      weight: berat,
      head_size: pala,
      blood_type: darah,
      disease_history: riwayat,
      disease: penyakit,
      distance_house: jarak,
      traveling_time: waktu,
    },
  });
};
export const sendDataParent = (
  usrnm,
  p0,
  nik0,
  year0,
  ed0,
  work0,
  gaji0,
  phone0,
  p1,
  nik1,
  year1,
  ed1,
  work1,
  gaji1,
  phone1,
  p2,
  nik2,
  year2,
  ed2,
  work2,
  gaji2,
  phone2,
) => {
  return Axios({
    method: "post",
    url: url + "/ppdb/sendDataParent",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      username: usrnm,
      name_parent_0: p0,
      birth_year_0: year0,
      nik_0: nik0,
      phone_number_0: phone0,
      education_0: ed0,
      work_0: work0,
      salary_0: gaji0,
      name_parent_1: p1,
      birth_year_1: year1,
      nik_1: nik1,
      phone_number_1: phone1,
      education_1: ed1,
      work_1: work1,
      salary_1: gaji1,
      name_parent_2: p2,
      birth_year_2: year2,
      nik_2: nik2,
      phone_number_2: phone2,
      education_2: ed2,
      work_2: work2,
      salary_2: gaji2,
    },
  });
};
