import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "../styles/Unit.css";
import * as BsIcons from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import {
  getActiveBatch,
  getMovingActiveBatch,
  getInternalActiveBatch,
} from "../api_folder/api";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

function UnitSMAIT() {
  const nav = useNavigate();
  const submitReguler = (e) => {
    let unitSMAIT = { id_batch: 91, name_unit: "SMAIT Asy-Syukriyyah" };
    e.preventDefault();
    nav("/register-reguler", { state: unitSMAIT });
  };
  const submitMoving = (e) => {
    let unitSMAIT = {
      id_batch: 104,
      name_unit: "Pindahan SMAIT Asy-Syukriyyah",
    };
    e.preventDefault();
    nav("/register-pindahan", { state: unitSMAIT });
  };
  const [reg, setReg] = useState([]);
  const [inter, setInter] = useState([]);
  const [moving, setMoving] = useState([]);

  useEffect(() => {
    getActiveBatch().then((res) => {
      setReg(res.data);
    });
    getInternalActiveBatch().then((res) => {
      setInter(res.data);
    });
    getMovingActiveBatch().then((res) => {
      setMoving(res.data);
    });
  }, []);

  let aReg = [];
  let iReg = [];
  let mReg = [];
  if (reg.status !== "fail") {
    reg.forEach((e) => {
      aReg.push(e.name_unit);
    });
  }
  if (inter.status !== "fail"){
    inter.forEach((e) => {
      iReg.push(e.name_unit);
    });
  }

  if (moving.status !== "fail") {
    moving.forEach((e) => {
      mReg.push(e.name_unit);
    });
  }
  return (
    <section>
      <Navbar expand="sm" variant="custom" bsPrefix={""} sticky="top">
        <Navbar.Brand>
          <img
            src="/images/logo.svg"
            width="44"
            height="45"
            className="d-inline-block align-top ms-3"
            alt="logo"
          />
        </Navbar.Brand>
        <Nav>
          <Nav.Link>PPDB Asy-Syukriyyah Islamic School</Nav.Link>
        </Nav>
      </Navbar>
      <Container className="p-3 p-md-5">
        <Link to="/" className="back">
          <BsIcons.BsArrowLeftShort /> Kembali ke halaman utama
        </Link>
        <span className="circlexunit" />
        <span className="circleyunit" />

        <Row className="mt-5 px-1">
          <Col md={12} lg={8} className="d-flex flex-column gap-3">
            <div>
              <div style={{ color: "#00923F" }}>
                <h1 style={{ color: "#364152" }}>SMAIT Asy-Syukriyyah</h1>
              </div>
              <p className="text-body-regsuccess">
                Pendaftaran peserta didik SMAIT Asy-Syukriyyah
              </p>
              <hr />
              <div style={{ color: "#364152" }}>
                <h4 style={{ marginTop: "64px" }}>
                  Persyaratan Pendaftaran SMAIT Asy-Syukriyyah
                </h4>
                <p className="text-body-unitsuccess">
                  Apa saja yang akan diperlukan untuk mendaftarkan peserta didik
                  baru.
                </p>
              </div>
            </div>
            <div className="infoBox listColored">
              <h6 style={{ color: "#364152" }}>📝 Persyaratan umum</h6>
              <ul>
                <li>
                  Melakukan pendaftaran secara online di alamat{" "}
                  <Link
                    style={{ color: "#00923F" }}
                    to="https://ppdb.asy-syukriyyah.sch.id/"
                  >
                    ppdb.asy-syukriyyah.sch.id
                  </Link>
                </li>
                <li>Mengikuti seleksi observasi dan wawancara orang tua</li>
                <li>Melengkapi berkas dokumen</li>
                <li>
                  Melunasi administrasi keuangan sampai waktu yang ditentukan
                </li>
              </ul>
            </div>
            <div className="infoBox listColored">
              <h6 style={{ color: "#364152" }}>📑 Persyaratan Dokumen</h6>
              <p>Dokumen dilampirkan pada saat tes masuk/observasi:</p>
              <ul>
                <li>Print out formulir pendaftaran online</li>
                <li>Print out Kartu Tes/Observasi</li>
                <li>Fotocopy Akte Kelahiran </li>
                <li>Fotocopy KTP Orang Tua</li>
                <li>Fotocopy Kartu Keluarga berbarcode</li>
                <li>Surat keterangan NISN dari sekolah asal</li>
                <li>Pas foto 3x4 sebanyak 2 lembar</li>
                <li>Print out MoU yang dapat di download di halaman login</li>
                <li>Materai tempel 10.000</li>
              </ul>
            </div>
            <div className="infoBox listColored">
              <h6 style={{ color: "#364152" }}>🗒️ Materi Tes Masuk</h6>
              <li>Tes Akademik (MTK, IPA, IPS)</li>
              <li>Tes Al Quran</li>
              <li>Tes Diagnostik</li>
              <li>Wawancara Orang Tua/Wali</li>
            </div>
            <div className="">
              <Row className="gap-4 p-3">
                <Col xs={12} className="infoBox">
                  <h6 style={{ color: "#364152" }}>📝 Ketentuan umum</h6>
                  <ul>
                    <li>
                      Masing-masing pendaftar akan mendapatkan nomor
                      pendaftaran.
                    </li>
                    <li>
                      Pembayaran biaya pendaftaran dilakukan melalui portal IDN
                      (infradigital).
                    </li>
                    <li>
                      Biaya pendaftaran sebagaimana tertera pada bukti
                      pendaftaran ditambah biaya admin.
                    </li>
                    <li>
                      Pembayaran biaya pendaftaran paling lambat 1 hari
                      terhitung dari hari pendaftaran.
                    </li>
                  </ul>
                </Col>
                <Col xs={12} className="infoBox">
                  <h6 style={{ color: "#364152" }}>
                    📄 Langkah-langkah pembayaran formulir registrasi reguler
                  </h6>
                  <ol>
                    <li>
                      Cek tagihan biaya formulir anda di{" "}
                      <Link
                        to={"https://www.infradigital.io/bills"}
                        style={{ color: "#00923F" }}
                      >
                        https://www.infradigital.io/bills
                      </Link>
                    </li>
                    <li>
                      Pilih unit pendidikan di kolom <b>“-Pilih Sekolah-”</b>
                    </li>
                    <li>
                      Masukkan nomor pendaftaran di kolom{" "}
                      <b>“Masukkan Nomor Induk Siswa (NIS)”</b>
                    </li>
                    <li>
                      Klik tombol <b>“Lihat Tagihan”</b>
                    </li>
                    <li>
                      Pastikan nominal tagihan dan nomor pendaftaran sudah
                      sesuai (ada tambahan biaya admin tergantung pilihan
                      pembayaran yg dipilih)
                    </li>
                    <li>
                      Pilih <b>“Cara bayar yang Anda kehendaki”</b>
                    </li>
                    <li>
                      Klik <b>"Lihat Cara Bayar"</b>
                    </li>
                    <li>
                      Ikuti petunjuk pembayaran sesuai yang tertera di{" "}
                      <Link
                        to={"https://www.infradigital.io/bills"}
                        style={{ color: "#00923F" }}
                      >
                        infradigital.io
                      </Link>
                    </li>
                    <li>
                      Konfirmasikan pembayaran biaya pendaftaran anda ke Call
                      Center unit pendidikan terkait untuk aktivasi akun.
                    </li>
                  </ol>
                </Col>
                <Col xs={12} className="infoBox">
                  <h6 style={{ color: "#364152" }}>📅 Kode unit pendidikan</h6>
                  <ul>
                    <li>
                      <span className="primColor">10620</span> - TK Islam
                      Terpadu Asy-Syukriyyah
                    </li>
                    <li>
                      <span className="primColor">10610</span> - MI Plus
                      Asy-Syukriyyah
                    </li>
                    <li>
                      <span className="primColor">10619</span> - SD Islam
                      Terpadu Asy-Syukriyyah
                    </li>
                    <li>
                      <span className="primColor">10697</span> - SDIT Quran
                      Asy-Syukriyyah Faturrahman
                    </li>
                    <li>
                      <span className="primColor">10622</span> - SMP Islam
                      Terpadu Asy-Syukriyyah
                    </li>
                    <li>
                      <span className="primColor">10623</span> - MTs Plus
                      Asy-Syukriyyah
                    </li>
                    <li>
                      <span className="primColor">10621</span> - SMA Islam
                      Terpadu Asy-Syukriyyah
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={"auto"} lg={2} xxl={3} className="mt-3">
            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-1">
              <Col>
                <Card className="cardUnit  mx-auto mx-lg-0 ">
                  <Card.Img
                    variant="top"
                    src="/images/imgsmait.png"
                    alt="TKIT"
                    style={{
                      height: "220px",
                      objectFit: "contain",
                    }}
                    className="img-fluid"
                  />
                  <Card.Body>
                    <Card.Title>SMAIT Asy-Syukriyyah</Card.Title>
                    <Card.Text>
                      <span>Pendaftaran dibuka pada</span>{" "}
                      <span style={{ color: "#00923F" }}>18 Oktober 2024</span>
                    </Card.Text>
                    {aReg.includes("SMAIT Asy-Syukriyyah") ? (
                      <Form onSubmit={submitReguler}>
                        <Button
                          variant="unit"
                          className="rounded-pill mt-3"
                          type="submit"
                        >
                          Daftar Program Reguler
                        </Button>
                      </Form>
                    ) : (
                      <Button
                        variant="unitClosed"
                        className="rounded-pill mt-3"
                      >
                        Daftar Program Reguler
                      </Button>
                    )}
                    {/* INTERNAL */}
                    {iReg.includes("SMAIT Asy-Syukriyyah") ? (
                      <Link to="/register-internal">
                        <Button variant="unit" className="rounded-pill mt-3">
                          Daftar Program Internal
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        variant="unitClosed"
                        className="rounded-pill mt-3"
                      >
                        Daftar Program Internal
                      </Button>
                    )}
                    {/* PINDAHAN */}
                    {mReg.includes("SMAIT Asy-Syukriyyah") ? (
                      <Form onSubmit={submitMoving}>
                        <Button
                          variant="unit"
                          className="rounded-pill mt-3"
                          type="submit"
                        >
                          Daftar Program Pindahan
                        </Button>
                      </Form>
                    ) : (
                      <Button
                        variant="unitClosed"
                        className="rounded-pill mt-3"
                      >
                        Daftar Program Pindahan
                      </Button>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card
                  style={{ marginTop: "1rem" }}
                  className="cardUnit  mx-auto mx-0-lg"
                >
                  <Card.Body>
                    <Card.Title
                      style={{ color: "#00923F", textAlign: "center" }}
                    >
                      Kontak SMAIT Asy-Syukriyyah
                    </Card.Title>
                    <Card.Text
                      className="text-center"
                      style={{ color: "#697586" }}
                    >
                      Email: smait@asy-syukriyyah.sch.id
                    </Card.Text>
                    <Button
                      target="_blank"
                      variant="success"
                      className="rounded-pill mt-3"
                      href="https://wa.me/+6281212330894"
                    >
                      WhatsApp
                    </Button>
                  </Card.Body>
                </Card>
                <Card
                  style={{ marginTop: "1rem" }}
                  className="cardUnit  mx-auto mx-0-lg"
                >
                  <Card.Body>
                    <Card.Title
                      style={{ color: "#00923F", textAlign: "center" }}
                    >
                      Brosur SMAIT Asy-Syukriyyah
                    </Card.Title>
                    <Card.Text
                      className="text-center"
                      style={{ color: "#697586" }}
                    >
                      Untuk informasi selanjutnya silahkan download brosur
                      sekolah di bawah ini.
                    </Card.Text>
                    <Button
                      variant="success"
                      className="rounded-pill mt-3"
                      target="_blank"
                      href="https://drive.google.com/file/d/14wMSFN7AemqTd1BhO1yGyLBZ_sa9Kfal/view?usp=share_link"
                    >
                      Download Brosur
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default UnitSMAIT;